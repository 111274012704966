<template>
  <WeContainer v-if="ready" header="true" v-bind:actions="actions">
    <WeTable
      v-bind:index="false"
      v-bind:data.sync="xmlTemplate.list"
      v-bind:columns="columns"
      v-bind:actions="tableActions"
      v-bind:multiLanguage="false"
      v-on:on-action="onAction"
      v-on:on-switch="onSwitch"
      v-bind:stateName="'xml-template'"
    ></WeTable>
  </WeContainer>
  <WeLoading v-else />
</template>

<script>
import { mapState, mapActions } from "vuex";
export default {
  name: "List",
  data() {
    return {
      ready: false,
      actions: [
        {
          text: "Xml Şablonu Oluştur",
          path: "xml-template/new",
        },
      
      ],
      tableActions: [
        {
          icon: "fas fa-edit",
          class: "btn-outline-primary",
          action: "edit",
        },
      ],
      columns: [
        { name: "name", th: "Şablon Adı", type: "string" },
        { name: "is_active", th: "Durum", type: "boolean", width: "10%" },
      ],
    };
  },
  methods: {
    ...mapActions("xmlTemplate", ["getList", "isActive"]),
    onAction(data) {
      if (data.key == "edit") {
        this.onEdit(data.row);
      }
    },
    onEdit(row) {
      this.$router.replace("xml-template/detail/" + row.id);
    },
    onSwitch(data) {
      const checkPermission = this.checkPermission("u");
      if (!checkPermission) {
        permission.alert(this);
      } else {
        this.isActive({
          form: data,
          onSuccess: (result) => {
            if (result && result.data && result.data.status !== "error") {
              this.$toast.success("Durum Güncellendi");
            } else {
              this.$toast.error("Durum Güncellenemedi");
            }
          },
          onError: (error) => {
            this.$toast.error("Durum Güncellenemedi");
          },
        });
      }
    },
    showAlert(type, message) {
      if (type) {
        switch (type) {
          case "success":
            this.$toast.success(message);
            break;

          default:
            this.$toast.error(message);
            break;
        }
      } else {
        this.showAlert("error", message);
      }
    },
    checkPermission(code) {
      return permission.check("brand", code);
    },
  },
  computed: {
    ...mapState(["shared", "xmlTemplate"]),
  },
  mounted() {
    this.getList({
      onSuccess: (result) => {
        this.ready = true;
      },
    });
  },
};
</script>
